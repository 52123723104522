import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

const LoginGuide = ({ vided1, login1, login2, login3, login4, selectedTitle }) => {
  return (
    <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
      <p className="text-xl font-semibold text-gray-800 mb-4">
        This guide will walk you through the steps to Login. Follow these steps carefully, and refer to the images provided at each step for visual guidance.
      </p>
      <div className="bg-white p-4 rounded-lg shadow-md mb-4">
        <p className="text-md leading-7 font-binance-plex">Step 1: Open the Login Page</p>
        <p className="text-md leading-7 font-binance-plex">Step 2: Provide Your Credentials</p>
        <p className="text-md font-binance-plex leading-7">
          Step 3: Click{" "}
          <a href="https://app.odecent.com/" target="_blank" className="text-SecondaryColor underline font-semibold inline-flex items-center space-x-1">
            <span className="text-SecondaryColor font-bold">Login</span>
            <FaExternalLinkAlt />
          </a>
        </p>
        <p className="text-md font-binance-plex leading-7">Step 4: Access Your Account</p>
      </div>

      {/* {selectedTitle === "login" && (
        <div className="my-4 flex justify-center" id="login">
          <video controls width="640" height="360" id="login">
            <source src={vided1} type="video/mp4" />
          </video>
        </div>
      )} */}


<div className=" flex flex-col gap-2 justify-center items-center my-2">

      
<p className=" text-lg leading-6 mt-2 font-bold text-SecondaryColor">
Your Guide to Login: A Complete Walkthrough Video
    </p>
    <div className="w-full max-w-4xl aspect-video">
    <iframe  className="w-full h-full"  src="https://www.youtube.com/embed/s9UL6wbDQ08?si=1G8M5jIodWa9NytO" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
</div>
    </div>

<div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">1:</span> Access the Login Page
            </p>
            <p className="text-md text-gray-700 leading-7 mt-2">
              Begin by navigating to the Odecent login page. Enter your username
              and password in the designated fields and click on the "Login"
              button to access your account.
              <a
                href="https://app.odecent.com/"
                target="_blank"
                className="text-SecondaryColor underline font-semibold ml-1"
              >
                app.odecent.com
              </a>
            </p>
            <img src={login1} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">2:</span>
              Provide Your Credentials
            </p>
            <p className="text-md font-binance-plex leading-7">
              Enter your username & password in the designated fields.
            </p>
            <img src={login3} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">3:</span>Click Login:
            </p>
            <p className="text-md font-binance-plex leading-7">
              Click the{" "}
              <span className="text-colorAccent  font-semiboldbold">
                "Login"{" "}
              </span>{" "}
              button to submit your credentials for verification.
            </p>
            <img src={login2} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-lg font-bold text-SecondaryColor flex items-center">
              <span className="mr-2">4:</span>
              Access Your Account
            </p>
            <p className="text-md font-binance-plex leading-7">
              If your credentials are correct, you'll be granted access to your
              account's{" "}
              <span className="text-colorAccent">Odecent Dashboard</span>.
            </p>
            <img src={login4} alt="" className="my-4" />
          </div>
    </div>
  );
};

export default LoginGuide;
