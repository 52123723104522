import React from "react";
import { useState, useEffect } from "react";
import guide from "../assets/img/guideImg.webp";
import guidemobile from "../assets/img/mobileimgbanner.webp";
import connectLive6 from "../assets/img/connnectLive/5.webp";
import connectLive7 from "../assets/img/connnectLive/6.webp";
import connectLive13 from "../assets/img/connnectLive/12.webp";
import connectLive14 from "../assets/img/connnectLive/13.webp";
import connectLive15 from "../assets/img/connnectLive/14.webp";
import connectLive8 from "../assets/img/connnectLive/15.webp";
import connectLive9 from "../assets/img/connnectLive/16.webp";
import connectpaper1 from "../assets/img/Paperapi/1.webp";
import connectpaper2 from "../assets/img/Paperapi/2.webp";
import connectpaper4 from "../assets/img/Paperapi/4.webp";
import connectpaper5 from "../assets/img/Paperapi/5.webp";
import bot1 from "../assets/img/Bot/1.webp";
import b2 from "../assets/img/Bot/2.webp";
import bot3 from "../assets/img/Bot/3.webp";
import bot4 from "../assets/img/Bot/44.webp";
import bot5 from "../assets/img/Bot/5.webp";
import bot6 from "../assets/img/Bot/6.webp";
import bot7 from '../assets/img/Bot/7.webp'
import { AiOutlineLogin } from "react-icons/ai";
import { BsPersonFillAdd } from "react-icons/bs";
import { AiFillApi } from "react-icons/ai";
import { TbPremiumRights } from "react-icons/tb";
import {  RiRobot2Line } from "react-icons/ri";
import { PiPlugsConnectedBold } from "react-icons/pi";
import {  BiMenuAltRight } from "react-icons/bi";
import vided1 from "../assets/img/loginVid.mp4";
import Signupvid from "../assets/img/Signup.mp4";
import login1 from "../assets/img/loginNew/step1.webp";
import login2 from "../assets/img/loginNew/step2.webp";
import login3 from "../assets/img/loginNew/step2.webp";
import login4 from "../assets/img/singUpnew/step4.webp";
import active1 from '../assets/img/activebot/1.webp'
import active2 from "../assets/img/activebot/2.webp";
import active3 from "../assets/img/activebot/3.webp";
import trades1 from "../assets/img/trades/1.webp";
import trades2 from "../assets/img/trades/2.webp";
import trades3 from "../assets/img/trades/3.webp";
import trades4 from "../assets/img/trades/4.webp";
import trades5 from "../assets/img/trades/5.webp";
import trades6 from "../assets/img/trades/6.webp";

import singup1 from "../assets/img/singUpnew/step1.webp";
import singup0 from "../assets/img/singUpnew/step2.webp";
import singup2 from "../assets/img/singUpnew/step2.webp";
import singup4 from "../assets/img/singUpnew/step3.webp";
import sub1 from "../assets/img/singUpnew/step4.webp";
import sub2 from "../assets/img/subscription/step2.webp";
import sub3 from "../assets/img/subscription/step3.webp";
import sub4 from "../assets/img/subscription/step4.webp";
import { cardData } from "./data";
import { Link, useNavigate } from "react-router-dom";
import { SiProbot } from "react-icons/si";
import { BsBarChart } from "react-icons/bs";
import { FaChartBar } from "react-icons/fa";


import livetrades1 from "../assets/img/livetrades/1.webp";
import livetrades2 from "../assets/img/livetrades/2.webp";
import livetrades3 from "../assets/img/livetrades/3.webp";
import livetrades4 from "../assets/img/livetrades/4.webp";
import livetrades5 from "../assets/img/livetrades/5.webp";
import livetrades6 from "../assets/img/livetrades/6.webp";

import livetrades7 from "../assets/img/livetrades/7.webp";
import livetrades8 from "../assets/img/livetrades/8.webp";
import livetrades9 from "../assets/img/livetrades/9.webp";
import livetrades10 from "../assets/img/livetrades/10.webp";
import livetrades11 from "../assets/img/livetrades/11.webp";
import livetrades12 from "../assets/img/livetrades/12.webp";
import livetrades13 from "../assets/img/livetrades/13.webp";

import { Drawer } from "antd";
import Guidecarddata from "./Guidecarddata";
import { ImProfile } from "react-icons/im";
import {
  PiPasswordFill,
  PiSwap,
  PiUserSwitchFill,
  PiHandWithdrawFill,
} from "react-icons/pi";
import { TbAuth2Fa } from "react-icons/tb";

import { FaMoneyBillTransfer } from "react-icons/fa6";
import { TfiWallet } from "react-icons/tfi";
import deposit1 from "../assets/img/howtodeposit/2.webp";
import deposit2 from "../assets/img/howtodeposit/3.webp";
import deposit3 from "../assets/img/howtodeposit/4.webp";
import deposit4 from "../assets/img/howtodeposit/5.webp";
import deposit5 from "../assets/img/howtodeposit/6.webp";
import deposit6 from "../assets/img/howtodeposit/7.webp";
import deposit7 from "../assets/img/howtodeposit/8.webp";

import kyc1 from "../assets/img/kyc/2.png";
import kyc2 from "../assets/img/kyc/3.png";
import kyc3 from "../assets/img/kyc/4.png";
import kyc4 from "../assets/img/kyc/5.webp";
import kyc5 from "../assets/img/kyc/6.png";
import kyc6 from "../assets/img/kyc/7.webp";
import kyc7 from "../assets/img/kyc/8.png";

import tp1 from "../assets/img/transactionpass/2.webp";
import tp2 from "../assets/img/transactionpass/3.webp";
import tp3 from "../assets/img/transactionpass/4.webp";
import tp4 from "../assets/img/transactionpass/5.webp";
import tp5 from "../assets/img/transactionpass/6.webp";
import tp6 from "../assets/img/transactionpass/7.webp";
import tp7 from "../assets/img/transactionpass/8.webp";
import tp8 from "../assets/img/transactionpass/9.webp";
import tp9 from "../assets/img/transactionpass/10.webp";
import tp10 from "../assets/img/transactionpass/11.webp";
import tp11 from "../assets/img/transactionpass/12.webp";
import wa1 from "../assets/img/walletaddress/2.webp";
import wa2 from "../assets/img/walletaddress/3.webp";
import wa3 from "../assets/img/walletaddress/4.webp";
import wa4 from "../assets/img/walletaddress/5.webp";
import wa5 from "../assets/img/walletaddress/6.webp";
import wa6 from "../assets/img/walletaddress/7.webp";

import tr1 from "../assets/img/transferreward/2.webp";
import tr2 from "../assets/img/transferreward/3.webp";
import tr3 from "../assets/img/transferreward/4.webp";
import tr4 from "../assets/img/transferreward/5.webp";
import tr5 from "../assets/img/transferreward/6.webp";


import p2p1 from "../assets/img/p2p/2.webp";
import p2p2 from "../assets/img/p2p/3.webp";
import p2p3 from "../assets/img/p2p/4.webp";
import p2p4 from "../assets/img/p2p/5.webp";
import p2p5 from "../assets/img/p2p/6.webp";
import p2p6 from "../assets/img/p2p/7.webp";
import w1 from "../assets/img/withdrawal/2.webp";
import w2 from "../assets/img/withdrawal/3.webp";
import w4 from "../assets/img/withdrawal/5.webp";
import w5 from "../assets/img/withdrawal/6.webp";
import w6 from "../assets/img/withdrawal/7.webp";
import w7 from "../assets/img/withdrawal/8.webp";
import w8 from "../assets/img/withdrawal/9.webp";
import GoogleTranslate from "../Component/GoogleTranslate";

import LoginGuide from "../Component/Login/LoginGuide";
import SignUpGuide from "../Component/Guide/SignUpGuide";
import TransferReward from "../Component/Guide/TransferReward";
import WalletAddressGuide from "../Component/Guide/WalletAddressGuide";
import TransactionPasswordGuide from "../Component/Guide/TransactionPasswordGuide";
import TopupGuide from "../Component/Guide/TopupGuide";
import BuySubscription from "../Component/Guide/BuySubscription";
import KYCGuide from "../Component/Guide/KYCGuide";
import LiveAPIGuide from "../Component/Guide/LiveAPIGuide";
import CopyBot from "../Component/Guide/CopyBot";
import PaperAPIGuide from "../Component/Guide/PaperAPIGuide";

import ActiveBotsGuide from "../Component/Guide/ActiveBotsGuide";
import TradesGuide from "../Component/Guide/PaperTradesGuide";
import P2PGuide from "../Component/Guide/P2PGuide";
import WithdrawalGuide from "../Component/Guide/WithdrawalGuide";
import LiveTradeGuide from "../Component/Guide/LiveTradeGuide";
import WeeklyReward from "../Component/Guide/WeeklyReward";
import Configure2FA from "../Component/Guide/Configure2FA";

const Guide = () => {
  const [selected, setSelected] = useState(0);
  const [selectedTitle, setSelectedTitle] = useState("signin");
  const [mappingData, setMappingData] = useState([]);
  const [checkTech, setCheckTech] = useState();
  const [showPage, setShowPage] = useState(true);
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);

  const navigate = useNavigate();

  const showDrawer2 = () => {
    setOpen2(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  const onClose2 = () => {
    setOpen2(false);
  };

  useEffect(() => {
    const hash = window.location.hash; 
    if (!selectedTab) {  
      if (hash === "#paperapi" || hash === "#liveapi" || hash === "#createbot" || hash === "#activebot" || hash === "papertrade" || hash === "livetrade") {
        setSelectedTab("Trade");
      } else {
        setSelectedTab("App");
      }
    }
  }, []);
  
  

  const [selectedTab, setSelectedTab] = useState("");

  const OdecentSidebar = [
    {
      topic: "SIGN UP PAGE",
      icon: <BsPersonFillAdd className="text-xl " />,
      id: "signin",
    },
    {
      topic: "LOGIN PAGE",
      icon: <AiOutlineLogin className="text-xl " />,
      id: "login",
    },
    {
      topic: "TOPUP",
      icon: <FaMoneyBillTransfer className="text-xl " />,
      id: "topupc",
    },
    // {
    //   topic: "TOPUP ODECENT PAYMENT",
    //   icon: <FaMoneyBillTransfer className="text-xl " />,
    //   id: "topupo",
    // },
    
    {
      topic: "BUY SUBSCRIPTION",
      icon: <TbPremiumRights className="text-xl " />,
      id: "subs",
    },
    { topic: "KYC", icon: <ImProfile className="text-xl " />, id: "kyc" },
    {
      topic: "TRANSACTION PASSWORD",
      icon: <PiPasswordFill className="text-xl " />,
      id: "transactionpassword",
    },
    {
      topic: "WALLET ADDRESS",
      icon: <TfiWallet className="text-xl " />,
      id: "walletaddress",
    },
    {
      topic: "TRANSFER REWARD",
      icon: <PiSwap className="text-xl " />,
      id: "transferreward",
    },
    {
      topic: "WEEKLY REWARD",
      icon: <PiSwap className="text-xl " />,
      id: "weeklyreward",
    },
    {
      topic: "P2P",
      icon: <PiUserSwitchFill className="text-xl " />,
      id: "p2p",
    },
    {
      topic: "WITHDRAWAL",
      icon: <PiHandWithdrawFill className="text-xl " />,
      id: "withdrawal",
    },
    // {
    //   topic: "CONFIGURE 2FA",
    //   icon: <TbAuth2Fa className="text-xl " />,
    //   id: "config2fa",
    // },
  ];

  const TradeSidebar = [
    {
      topic: "LOGIN PAGE",
      icon: <AiOutlineLogin className="text-xl " />,
      id: "login",
    },
    {
      topic: "TOPUP",
      icon: <FaMoneyBillTransfer className="text-xl " />,
      id: "topupc",
    },
    {
      topic: "BUY SUBSCRIPTION",
      icon: <TbPremiumRights className="text-xl " />,
      id: "subs",
    },
    {
      topic: "CONNECT TO PAPER API",
      icon: <AiFillApi className="text-xl " />,
      id: "paperapi",
    },
    {
      topic: "CONNECT TO LIVE API",
      icon: <PiPlugsConnectedBold className="text-xl " />,
      id: "liveapi",
    },
    {
      topic: "COPYING A BOT",
      icon: <RiRobot2Line className="text-xl " />,
      id: "createbot",
    },
   
    {
      topic: "ACTIVE BOTS",
      icon: <SiProbot className="text-xl " />,
      id: "activebot",
    },
    {
      topic: "PAPER TRADES",
      icon: <FaChartBar className="text-xl " />,
      id: "papertrade",
    },
    {
      topic: "LIVE TRADES",
      icon: <BsBarChart className="text-xl " />,
      id: "livetrade",
    },
    // {
    //   topic: "CONFIGURE 2FA",
    //   icon: <TbAuth2Fa className="text-xl " />,
    //   id: "config2fa",
    // },
  ];

  const topics = selectedTab === "App" ? OdecentSidebar : TradeSidebar;

  const primaryData = [


    // {
    //   filterTitle: "CONFIGURE 2FA",
    //   title: "CONFIGURE 2FA",
    //   id: "config2fa",
    //   mappingData: (
    //   <Configure2FA/>
    //   ),
    // },


    {
      filterTitle: "LOGIN PAGE",
      title: "LOGIN PAGE",
      id: "login",
      mappingData: (
        <LoginGuide
          vided1={vided1}
          login1={login1}
          login2={login2}
          login3={login3}
          login4={login4}
          selectedTitle={selectedTitle}
        />
      ),
    },
    {
      filterTitle: "SIGN UP PAGE",
      title: "SIGN UP PAGE",
      id: "signin",
      mappingData: (
        <SignUpGuide
          Signupvid={Signupvid}
          singup1={singup1}
          singup0={singup0}
          singup2={singup2}
          singup4={singup4}
          login3={login3}
        />
      ),
    },
    {
      filterTitle: "TRANSFER REWARD",
      title: "TRANSFER REWARD",
      id: "transferreward",
      mappingData: (
        <TransferReward
          login1={login1}
          tr1={tr1}
          tr2={tr2}
          tr3={tr3}
          tr4={tr4}
          tr5={tr5}
        />
      ),
    },

    {
      filterTitle: "WEEKLY REWARD",
      title: "WEEKLY REWARD",
      id: "weeklyreward",
      mappingData: (
        <WeeklyReward
          login1={login1}
          tr1={tr1}
          tr2={tr2}
          tr3={tr3}
          tr4={tr4}
          tr5={tr5}
        />
      ),
    },

    {
      filterTitle: "WALLET ADDRESS",
      title: "WALLET ADDRESS",
      id: "walletaddress",
      mappingData: (
        <WalletAddressGuide
          login1={login1}
          wa1={wa1}
          wa2={wa2}
          wa3={wa3}
          wa4={wa4}
          wa5={wa5}
          wa6={wa6}
        />
      ),
    },

    {
      filterTitle: "TRANSACTION PASSWORD",
      title: "Transaction Password",
      id: "transactionpassword",
      mappingData: (
        <TransactionPasswordGuide
          login1={login1}
          tp1={tp1}
          tp2={tp2}
          tp3={tp3}
          tp4={tp4}
          tp5={tp5}
          tp6={tp6}
          tp7={tp7}
          tp8={tp8}
          tp9={tp9}
          tp10={tp10}
          tp11={tp11}
        />
      ),
    },
    {
      filterTitle: "TOPUP",
      title: "How to Deposit Funds Using CoinPayment",
      id: "topupc",
      mappingData: (
        <TopupGuide
          login1={login1}
          deposit1={deposit1}
          deposit2={deposit2}
          deposit3={deposit3}
          deposit4={deposit4}
          deposit5={deposit5}
          deposit6={deposit6}
          deposit7={deposit7}
        />
      ),
    },

    {
      filterTitle: "BUY SUBSCRIPTION",
      title: "HOW TO BUY SUBSCRIPTION",
      id: "subs",
      mappingData: (
        <BuySubscription sub1={sub1} sub2={sub2} sub3={sub3} sub4={sub4} />
      ),
    },

    {
      filterTitle: "KYC",
      title: "How to Complete Your KYC Verification",
      id: "kyc",
      mappingData: (
        <KYCGuide
          login1={login1}
          kyc1={kyc1}
          kyc2={kyc2}
          kyc3={kyc3}
          kyc4={kyc4}
          kyc5={kyc5}
          kyc6={kyc6}
          kyc7={kyc7}
        />
      ),
    },

    {
      filterTitle: "CONNECT TO LIVE API",
      title: "HOW TO CONNECT TO LIVE API ",
      id: "liveapi",
      mappingData: (
        <LiveAPIGuide
          connectpaper1={connectpaper1}
          connectLive6={connectLive6}
          connectLive14={connectLive14}
          connectLive15={connectLive15}
          connectLive13={connectLive13}
          connectLive7={connectLive7}
          connectLive8={connectLive8}
          connectLive9={connectLive9}
        />
      ),
    },

    {
      filterTitle: "COPYING A BOT",
      title: "HOW TO COPYING A BOT",
      id: "createbot",
      mappingData: (
        <CopyBot
          b1={bot1}
          b2={b2}
          bot3={bot3}
          bot4={bot4}
          bot5={bot5}
          bot6={bot6}
          bot7={bot7}
        />
      ),
    },
    {
      filterTitle: "CONNECT TO PAPER API",
      title: "HOW TO CONNECT TO PAPER API",
      id: "paperapi",
      mappingData: (
        <PaperAPIGuide
          connectpaper1={connectpaper1}
          connectpaper2={connectpaper2}
          connectpaper4={connectpaper4}
          connectpaper5={connectpaper5}
          connectLive15={connectLive15}
        />
      ),
    },

  

    {
      filterTitle: "ACTIVE BOTS",
      title: "HOW TO VIEW YOUR ACTIVE BOTS",
      id: "activebot",
      mappingData: (
        <ActiveBotsGuide
          active1={active1}
          active2={active2}
          active3={active3}
        />
      ),
    },

    {
      filterTitle: "PAPER TRADES",
      title: "HOW TO MONITOR YOUR PAPER TRADES",
      id: "papertrade",
      mappingData: (
        <TradesGuide trades1={trades1} trades2={trades2} trades3={trades3} trades4={trades4} trades5={trades5} trades6={trades6} />
      ),
    },

    {
      filterTitle: "LIVE TRADES",
      title: "HOW TO MONITOR YOUR LIVE TRADES",
      id: "livetrade",
      mappingData: (
        <LiveTradeGuide livetrades1={livetrades1} livetrades2={livetrades2} livetrades3={livetrades3} livetrades4={livetrades4} livetrades5={livetrades5} livetrades6={livetrades6} livetrades7={livetrades7} livetrades8={livetrades8} livetrades9={livetrades9} livetrades10={livetrades10} livetrades11={livetrades11} livetrades12={livetrades12} livetrades13={livetrades13}/>
      ),
    },
    // {
    //   filterTitle: "REPORT",
    //   title: "HOW TO ACCESS YOUR REPORTS",
    //   id: "report",
    //   mappingData: (
    //     <ReportGuide report1={report1} report2={report2} report3={report3} />
    //   ),
    // },

    {
      filterTitle: "P2P",
      title: "How to Complete Your Transfer Fund",
      id: "p2p",
      mappingData: (
        <P2PGuide
          login1={login1}
          p2p1={p2p1}
          p2p2={p2p2}
          p2p3={p2p3}
          p2p4={p2p4}
          p2p5={p2p5}
          p2p6={p2p6}
        />
      ),
    },

    {
      filterTitle: "WITHDRAWAL",
      title: "WITHDRAWAL",
      id: "withdrawal",
      mappingData: (
        <WithdrawalGuide
          login1={login1}
          w1={w1}
          w2={w2}
          w4={w4}
          w5={w5}
          w6={w6}
          w7={w7}
          w8={w8}
        />
      ),
    },
  ];

  useEffect(() => {
    const dofilte = primaryData.filter((item) => item.id == selectedTitle);

    setMappingData(dofilte);
  }, [selectedTitle]);

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.replace("#", "");
      const matchedTopic = topics.find((topic) => topic.id === hash);
      if (matchedTopic) {
        setSelectedTitle(matchedTopic.id);
      }
    };

    window.addEventListener("hashchange", handleHashChange);
    handleHashChange(); // Call it initially to handle the current hash

    return () => {
      window.removeEventListener("hashchange", handleHashChange);
    };
  }, []);

  const targetElementStyle = {
    display: "block",
    position: "relative",
    top: "-150px",
    visibility: "hidden",
  };

  const finGuideStyle = {
    display: "none",
    position: "relative",
    top: "-180px",
    visibility: "hidden",
  };

  const showGuide = () => {
    setShowPage(false);
  };

  const handleTabChange = (tab) => {
    setSelectedTab(tab);
    if (tab === "Trade") {
      setSelectedTitle("login");
    }else{
      setSelectedTitle("signin")
    }
  };

  return (
    <div className=" bg-[#]">
      <div
        className="xl:block hidden w-full  h-[510px] bg-no-repeat  justify-center items-center "
        style={{
          backgroundImage: `url(${guide})`,
          backgroundSize: "100% 100%",
        }}
      >
        <div className="lg:pt-32   ">
          <p class="borderText text-center text-white">Document Details </p>
        
        </div>
      </div>
      <Drawer
        title="Technical guide"
        placement="right"
        onClose={onClose}
        open={open}
      >
        <Guidecarddata
          showFunc={{ showGuide, showPage, onClose, setCheckTech }}
        />
        {/* <Guidecarddata /> */}
      </Drawer>

      <div className=" min-h-screen 2xl:w-[85%] mx-auto h-auto ">
        <div className="lg:h-20 w-full flex items-center justify-between sticky_navbar_guide bg-white z-50">
          <div className="mx-1 text-2xl font-semibold  flex items-center gap-2">
            <div className="w-36 lg:w-48 h-[40px] bg-primaryDark ">
              <div className=" flex justify-center items-center h-full ">
                <img
                  src="https://imagedelivery.net/_61vLPSGQhg-TYoL9kq0Vg/9ca2cef3-f6d4-466f-2bef-464261d87b00/public"
                  alt=""
                  className="px-2  lg:block hidden"
                  onClick={() => navigate("/")}
                />
                <img
                  src="https://imagedelivery.net/_61vLPSGQhg-TYoL9kq0Vg/9ca2cef3-f6d4-466f-2bef-464261d87b00/public"
                  alt="short"
                  className="px-2  lg:hidden block"
                  onClick={() => navigate("/")}
                />
              </div>
            </div>

            <div className="flex justify-center">
              <nav className="lg:flex hidden overflow-x-auto items-center p-1 space-x-1 rtl:space-x-reverse text-sm text-gray-600 bg-gray-500/5 rounded-xl dark:bg-gray-500/20">
                <button
                  role="tab"
                  type="button"
                  className={`${
                    selectedTab === "App"
                      ? "text-SecondaryColor bg-white shadow dark:text-white dark:bg-SecondaryColor"
                      : "hover:text-gray-800 focus:text-SecondaryColor dark:text-gray-400"
                  } flex whitespace-nowrap items-center h-8 px-5 font-medium rounded-lg outline-none focus:ring-inset transition-transform duration-300`}
                  onClick={() => handleTabChange("App")}
                >
                  App
                </button>

                <button
                  role="tab"
                  type="button"
                  className={`${
                    selectedTab === "Trade"
                      ? "text-SecondaryColor bg-white shadow dark:text-white dark:bg-SecondaryColor"
                      : "hover:text-gray-800 focus:text-SecondaryColor dark:text-gray-400"
                  } flex whitespace-nowrap items-center h-8 px-5 font-medium rounded-lg outline-none transition-transform duration-300`}
                  onClick={() => handleTabChange("Trade")}
                >
                  Trade
                </button>
              </nav>
            </div>

            <GoogleTranslate />
          </div>

          <div className="xl:hidden block">
            <div
              className="  rounded-lg px-3 py-2 text-lg  cursor-pointer hover:bg-primaryDark hover:text-white transition-all transition-5s"
              onClick={showDrawer2}
            >
              <BiMenuAltRight size={40} />
            </div>
            <Drawer
              title="Technical guide"
              placement="right"
              onClose={onClose2}
              open={open2}
            >
              <div className="px-2">
                <div className="flex justify-between">
                  <nav className="lg:hidden flex overflow-x-auto items-center p-1 space-x-1 rtl:space-x-reverse text-sm text-gray-600 bg-gray-500/5 rounded-xl dark:bg-gray-500/20">
                    <button
                      role="tab"
                      type="button"
                      className={`${
                        selectedTab === "App"
                          ? "text-SecondaryColor bg-white shadow dark:text-white dark:bg-SecondaryColor"
                          : "hover:text-gray-800 focus:text-SecondaryColor dark:text-gray-400 "
                      } flex whitespace-nowrap items-center h-8 px-5 font-medium rounded-lg outline-none  focus:ring-inset transition-transform duration-300`}
                      onClick={() => setSelectedTab("App")}
                    >
                      App
                    </button>

                    <button
                      role="tab"
                      type="button"
                      className={`${
                        selectedTab === "Trade"
                          ? "text-SecondaryColor bg-white shadow dark:text-white dark:bg-SecondaryColor"
                          : "hover:text-gray-800 focus:text-SecondaryColor dark:text-gray-400 "
                      } flex whitespace-nowrap items-center h-8 px-5 font-medium rounded-lg outline-none   transition-transform duration-300`}
                      onClick={() => setSelectedTab("Trade")}
                    >
                      Trade
                    </button>
                  </nav>

                  <div
                    className="bg-SecondaryColor w-[100px] flex justify-center text-white rounded-lg px-3 py-2 text-lg cursor-pointer hover:bg-primaryDark hover:text-white transition-all transition-5s"
                    onClick={() => {
                      window.location.href = "https://app.odecent.com";
                      onClose2();
                    }}
                  >
                    Login
                  </div>
                </div>

                {/*  */}

                <div className="  rounded-lg px-3 py-2 text-lg  cursor-pointer hover:bg-primaryDark hover:text-white transition-all transition-5s">
                  <ul className="border-r border-[#d5d5d5]">
                    {topics.map((item, index) => {
                      const formattedTopic = item.topic
                        .toLowerCase()
                        .replace(/\b\w/g, (c) => c.toUpperCase());
                      return (
                        <li
                          onClick={() => {
                            setSelected(index);
                            setSelectedTitle(item.id);
                            setShowPage(true);
                            onClose2();
                          }}
                          key={index}
                          className={`${
                            item.id == selectedTitle && showPage
                              ? " bg-SecondaryColor text-white border-colorAccent border-l-4 text-colorAccent"
                              : " bg-white"
                          }
                        text-base font-medium text-black capitalize py-3 rubik text-14 leading-20 lg:pl-6  pl-3 cursor-pointer`}
                        >
                          <a href={`#${item.id}`} className="no-underline ">
                            <span className=" flex items-center gap-2  md:gap-2">
                              <span
                                className={`flex items-center md:gap-2 ${
                                  index === selected && showPage
                                    ? ""
                                    : "text-colorAccent"
                                }`}
                              >
                                {item.icon}
                              </span>
                              <span className="xl:block">{item.topic}</span>
                            </span>
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                </div>
                {/* // */}
              </div>
            </Drawer>
          </div>
          <div className="lg:hidden xl:flex hidden   items-center gap-3 ">
            {/* <StateSelectComponent /> */}
            <div
              className=" underline rounded-lg px-3 py-2 text-lg  cursor-pointer hover:bg-primaryDark hover:text-white transition-all transition-5s"
              // onClick={() => navigate("https://app.odecent.com")}
            >
              <div className="px-8 rounded-lg py-2  bg-SecondaryColor text-white ">
                <Link to={"https://app.odecent.com"}>Login</Link>
              </div>
            </div>
          </div>
        </div>

        <div className="grid grid-cols-12 min-h-screen h-auto ">
          <div className="col-span-2 hidden lg:hidden xl:block ">
            <div className="border-2 border-SecondaryColor  py-14 rounded-3xl xl:w-auto md:w-[60px] w-[55px] stickyexample">
              <ul className="border-r border-[#d5d5d5]">
                {topics.map((item, index) => {
                  const formattedTopic = item.topic
                    .toLowerCase()
                    .replace(/\b\w/g, (c) => c.toUpperCase());
                  return (
                    <li
                      onClick={() => {
                        setSelected(index);
                        setSelectedTitle(item.id);
                        setShowPage(true);
                      }}
                      key={index}
                      className={`${
                        item.id === selectedTitle && showPage
                          ? "bg-SecondaryColor text-white border-colorAccent border-l-4 text-colorAccent"
                          : "bg-white"
                      }
        text-base font-medium text-black capitalize py-3 rubik text-14 leading-20 lg:pl-6 pl-3 cursor-pointer`}
                    >
                      <a href={`#${item.id}`}>
                        <span className="flex items-center md:gap-2">
                          <span
                            className={`flex items-center md:gap-2 ${
                              index === selected && showPage
                                ? ""
                                : "text-colorAccent"
                            }`}
                          >
                            {item.icon}
                          </span>
                          <span className="xl:block hidden">{item.topic}</span>
                        </span>
                      </a>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
          <div className=" lg:col-span-12 xl:col-span-10 col-span-12 lg:w-[100%] w-[100%]    h-auto overflow-y-auto">
            <img className="xl:hidden " src={guidemobile} alt="" />
            <div className=" mb-10">
              {showPage && (
                <div className="mx-[20px]">
                  <p
                    className=" text-3xl font-medium hidden"
                    id="finGuide"
                    style={finGuideStyle}
                  >
                    Odecent Beginner's Guide
                  </p>
                  <p className=" text-3xl font-medium" id="finGuide">
                    Odecent Beginner's Guide
                  </p>
                  <p className="mt-1 mb-6 ">
                    Welcome to{" "}
                    <span className="text-colorAccent">Odecent Dashboard</span>!
                    Kickstart your bot journey with quick and easy steps:
                  </p>
                  {mappingData.map((item, index) => {
                    return (
                      <div className="min-h-[300px] h-auto" key={item.id}>
                        <div className="w-[95%] mx-auto">
                          <p className="font-medium text-2xl leading-6 mb-4">
                            {item.title}
                          </p>
                          {Array.isArray(item.mappingData) ? (
                            item.mappingData.map((itemData, i) => (
                              <React.Fragment key={i}>
                                {itemData}
                              </React.Fragment>
                            ))
                          ) : (
                            <React.Fragment key={index}>
                              {item.mappingData}
                            </React.Fragment>
                          )}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}

              {!showPage && (
                <div className=" mb-10">
                  <h1 className=" text-3xl mt-10 font-medium">
                    <span className="text-colorAccent">Odecent Dashboard</span>{" "}
                    Technical guide
                  </h1>
                  {cardData.map((item, index) => {
                    return (
                      <>
                        <div
                          className="font-medium "
                          id={item.jumpTo}
                          style={targetElementStyle}
                        >
                          {item.title}
                        </div>
                        <div
                          className={`px-4  ${
                            checkTech == index
                              ? "  shadow-neumorphicnew py-2 border-2 rounded-3xl"
                              : ""
                          }`}
                        >
                          <div
                            className={`font-medium text-base leading-6 mt-2 ${
                              checkTech == index ? "text-colorAccent " : ""
                            }`}
                            id={item.jumpTo}
                          >
                            {item.title}
                          </div>
                          <p className="text-sm leading-7">
                            {item.description}
                          </p>
                        </div>
                      </>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Guide;
