import React from 'react'
import { useNavigate } from 'react-router-dom'


const PaperAPIGuide = ({connectpaper1,connectpaper2,connectpaper4,connectpaper5,connectLive15}) => {


    const navigate = useNavigate()

  return (
   <>
      <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Connect Paper API.
            Follow these steps carefully, and refer to the images provided at
            each step for visual guidance.
          </p>

         



          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Step 1: Login & Go to the Trade <span className='text-primaryColor font-bold'>Dashboard</span> 
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2. Click on the <span className='text-primaryColor font-bold'>API CREDENTIALS</span>  ,Select Exchange button and
              prefer <span className='text-primaryColor font-bold'>Paper Mode</span> 
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Provide the Required Information and Proceed
            </p>

            <p className="text-md font-binance-plex leading-7">
              Step 4: Confirmation Popup
            </p>
          </div>



          <div className=" flex flex-col gap-2 justify-center items-center my-2">

      
<p className=" text-lg leading-6 mt-2 font-bold text-SecondaryColor">
Your Guide to Connect Paper API: A Complete Walkthrough Video
    </p>
    <div className="w-full max-w-4xl aspect-video">
    <iframe className='w-full h-full' src="https://www.youtube.com/embed/riFaUUEpZU4?si=MqOnXFOhW743fveI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

      </div>
      </div>



          <hr class="border-2 border-gray-400 my-4" />
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
              Step 1. Go to the{" "}
              <span className="text-colorAccent">Odecent Dashboard</span>.
            </p>
            <img src={connectpaper1} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 2. Click on the{" "}
              <span className="text-colorAccent">"API CREDENTIALS"</span>
              ,Select Exchange button and prefer Paper Mode.
            </p>
            <img src={connectpaper2} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 3. Provide the necessary information, including the API Name,
              Amount in USDT & Click the "Next" button to proceed.
            </p>
            <img src={connectpaper4} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 4. A Confirmation popup will appear.
            </p>
            <img src={connectpaper5} alt="" className="my-4" />
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 5. After Clicking on Ok,you will be redirected to Bots .
            </p>
            <img src={connectLive15} alt="" className="my-4" />
          </div>
        </div>
   
   </>
  )
}

export default PaperAPIGuide