import React from 'react'

const ActiveBotsGuide = ({active1,active2,active3}) => {
  return (
    <>
      <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
          <p className="text-xl font-semibold text-gray-800 mb-4">
            This guide will walk you through the steps to Active Bots. Follow
            these steps carefully, and refer to the images provided at each step
            for visual guidance.
          </p>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-md font-binance-plex leading-7">
              Step 1: Access the <span className='text-primaryColor font-bold'> Dashboard </span> and Find the <span className='text-primaryColor font-bold'>'Bot'</span> Section
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 2: View  <span className='text-primaryColor font-bold'>Active Bots</span> 
            </p>
            <p className="text-md font-binance-plex leading-7">
              Step 3: Details of Your <span className='text-primaryColor font-bold'>Active Bots</span> 
            </p>
          </div>


          <div className=" flex flex-col gap-2 justify-center items-center my-2">

      
<p className=" text-lg leading-6 mt-2 font-bold text-SecondaryColor">
Your Guide to Trade your Bot : A Complete Walkthrough Video
    </p>
    <div className="w-full max-w-4xl aspect-video">
    <iframe  className='w-full h-full' src="https://www.youtube.com/embed/cUeVnoAdPrQ?si=-vsrvgD8rQxmEfWy" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

      </div>
      </div>

          <hr class="border-2 border-gray-400 my-4" />
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7 mt-3">
              Step 1. Go to the{" "}
              <span className="text-colorAccent">Odecent Dashboard</span> and
              locate the "Bot" section.
            </p>
            <img src={active1} alt="" className="my-4" />
          </div>

          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className="text-SecondaryColor font-bold text-lg leading-7">
              Step 2. Click on the "ACTIVE BOT" button.
            </p>
            <img src={active2} alt="" className="my-4" />
          </div>
          <div className="bg-white p-4 rounded-lg shadow-md mb-4">
            <p className=" font-bold text-base leading-7">
              <span className="text-SecondaryColor font-bold text-lg">
                {" "}
                Step 3. This will lead you to view your active bots details,
                which include the following information:
              </span>

              <ul className="list-disc ml-14">
                <li>
                  Date and Time: The date and time when a particular transaction
                  occurred.
                </li>
                <li> Bot ID: A unique identifier for a trading bot.</li>
                <li>
                  Bot Name: The name of the BOT
                </li>
                <li>
                  API Name: The name of the application programming interface
                  (API) used for communication or data exchange.
                </li>
                <li>
                  Exchange: The name of the trading platform or marketplace
                  where trading activities occur.
                </li>
                <li>
                  Exchange Type: The type of trading exchange, such as
                  cryptocurrency exchange.
                </li>
                <li>
                  Trade Type: The specific type of trade, such as buy, sell, or
                  hold.
                </li>
                <li>
                  Trading Symbol: The symbol or ticker representing a particular
                  financial asset.
                </li>
                <li>
                  Investment Amount: The money invested in a particular trade or
                  transaction.
                </li>
              
                <li>
                  Status of the Bot: The current status of a Bot, which can
                  be open, closed, pending, or other relevant states.
                </li>
              </ul>
            </p>
            <img src={active3} alt="" className="my-4" />
          </div>
        </div>
    </>
  )
}

export default ActiveBotsGuide