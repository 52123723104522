import React from "react";
import { FaExternalLinkAlt } from "react-icons/fa";

const WeeklyReward = ({ login1, tr1, tr2, tr3, tr4, tr5 }) => {
  return (
    <div className="bg-gradient-to-r from-blue-50 to-blue-100 p-6 rounded-lg shadow-lg mb-8">
      <p className="text-xl font-semibold text-gray-800 mb-4">
        This guide will walk you through the steps to Transfer Reward. Follow these steps carefully, and refer to the images provided at each step for visual guidance.
      </p>

      <div className="bg-white p-4 rounded-lg shadow-md mb-4">
        <p className="text-md leading-7 font-binance-plex">
          Step 1: Access the{" "}
          <a
            href="https://app.odecent.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-colorAccent underline font-semibold inline-flex items-center space-x-1"
          >
            <span className="text-SecondaryColor font-bold">Login</span>
            <FaExternalLinkAlt />
          </a>{" "}
          Page
        </p>
        <p className="text-md leading-7 font-binance-plex">
          Step 2: Navigate to the Transfer Wallet Section
        </p>
        <p className="text-md font-binance-plex leading-7">
          Step 3: Select the Rewards Wallet
        </p>
        <p className="text-md font-binance-plex leading-7">
          Step 4: Enter the Amount to Transfer
        </p>
        <p className="text-md font-binance-plex leading-7">
          Step 5: Transfer the Funds
        </p>
        <hr className="border-2 border-gray-400 my-4" />
      </div>

      <div className="bg-white p-4 rounded-lg shadow-md mb-4">
        <p className="font-bold text-SecondaryColor text-lg leading-6 mt-2">
          1. Access the Login Page
        </p>
        <p className="text-md font-binance-plex leading-7">
          Start by navigating to the Odecent login page{" "}
          <a
            href="https://app.odecent.com/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-colorAccent underline font-semibold inline-flex items-center space-x-1"
          >
            <span className="text-SecondaryColor font-bold">
              app.odecent.com
            </span>
            <FaExternalLinkAlt />
          </a>
          . Enter your username and password in the designated fields, then
          click{" "}
          <span className="text-SecondaryColor font-bold text-lg">Login</span>{" "}
          to access your account.
        </p>
        <img src={login1} alt="Login Guide" className="my-4" />
      </div>

      <div className="bg-white p-4 rounded-lg shadow-md mb-4">
        <p className="font-bold text-SecondaryColor text-lg leading-6 mt-2">
          2. Navigate to the Transfer Wallet Section
        </p>
        <p className="text-md font-binance-plex leading-7">
          Once logged in, go to User{" "}
          <span className="text-SecondaryColor font-bold text-lg">
            Dashboard ➔ Wallet ➔ Transfer Wallet
          </span>
          . This section is where you can manage and transfer your rewards.
        </p>
        <img src={tr1} alt="Transfer Wallet Section" className="my-4" />
      </div>

      <div className="bg-white p-4 rounded-lg shadow-md mb-4">
        <p className="font-bold text-SecondaryColor text-lg leading-6 mt-2">
          3. Select the Rewards Wallet
        </p>
        <p className="text-md font-binance-plex leading-7">
          In the Transfer Wallet section, select the rewards wallet from which
          you wish to transfer funds. You can transfer balances from the
          following reward types: Direct Bonus, Network Bonus, Rank Bonus,
          Matching Bonus, Weekly Reward.
        </p>
        <img src={tr2} alt="Select Rewards Wallet" className="my-4" />
      </div>

      <div className="bg-white p-4 rounded-lg shadow-md mb-4">
        <p className="font-bold text-SecondaryColor text-lg leading-6 mt-2">
          4. Enter the Amount to Transfer
        </p>
        <p className="text-md font-binance-plex leading-7">
          Enter the desired amount you want to transfer from your selected
          rewards wallet to your withdrawal wallet. Make sure the amount is
          accurate before proceeding.
          <br />
          <span className="text-SecondaryColor font-bold text-lg">
            Minimum $20 and Maximum $10000
          </span>
        </p>
        <img src={tr3} alt="Enter Amount" className="my-4" />
      </div>

      <div className="bg-white p-4 rounded-lg shadow-md mb-4">
        <p className="font-bold text-SecondaryColor text-lg leading-6 mt-2">
          5. Transfer the Funds
        </p>
        <p className="text-md font-binance-plex leading-7">
          Once you've entered the desired amount, click the "Transfer" button to
          move the funds from your selected rewards wallet to your withdrawal
          wallet.
        </p>
        <img src={tr4} alt="Transfer Funds" className="my-4" />
      </div>

      <div className="bg-white p-4 rounded-lg shadow-md mb-4">
        <p className="text-lg font-bold font-binance-plex leading-7">
          Note: There is a "Transfer All" button available for your convenience.
          By clicking this button, you can transfer the entire balance from all
          your rewards wallets to your withdrawal wallet in one step.
        </p>
      </div>

      <div className="bg-white p-4 rounded-lg shadow-md mb-4">
        <p className="font-bold text-SecondaryColor text-lg leading-7">
          Success! Your rewards have now been successfully transferred to your
          withdrawal wallet.
        </p>
        <img src={tr5} alt="Success" className="my-4" />
      </div>
    </div>
  );
};

export default WeeklyReward;
